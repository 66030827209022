/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"

import "./skeleton.min.css"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
                  author
              }
          }
      }
  `)

  return (
    <>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"five columns"}>
            <h1 style={{ margin: 0, marginTop: "20px" }}>
              <Link
                to="/"
                id={"title"}
                style={{
                  color: `#0c0c0c`,
                  textDecoration: `none`,
                  fontFamily: `Times New Roman`,
                }}
              >
                {data.site.siteMetadata.title}
              </Link>
            </h1>
          </div>
          <div id={"nav"} className={"seven columns"}>
            <ul>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/"
                  >
                    Aktuellt
                  </Link>
                </span>
              </li>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/images"
                  >
                    Bilder
                  </Link>
                </span>
              </li>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/bio"
                  >
                    Biografi
                  </Link>
                </span>
              </li>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/media"
                  >
                    Media
                  </Link>
                </span>
              </li>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/contact"
                  >
                    Kontakt
                  </Link>
                </span>
              </li>
              <li>
                <span>
                  <Link
                    style={{ textDecoration: `none`, color: `#0c0c0c` }}
                    to="/exhibitions"
                  >
                    Utställningar
                  </Link>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <hr/>
        <main>{children}</main>
      </div>
      <footer style={{ textAlign: "center", marginTop: "20px", color: "#aaa" }}>
        © {new Date().getFullYear()} {data.site.siteMetadata.author}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
